@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ABC Corporate Extra Light";
  src: url("assets/fonts/abc-corporate-extra-light.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "AB Chanel";
  src: url("assets/fonts/abchanel.ttf") format("truetype");
  font-style: normal;
}

@layer base {
  button,
  input,
  optgroup,
  select,
  textarea {
    font-size: initial;
    font-weight: initial;
    line-height: initial;
    letter-spacing: initial;
    color: initial;
    margin: initial;
    padding: initial;
  }

  .container {
    max-width: 1280px !important;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    background-color: white;
  }

  h1 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.3;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  h4 {
    font-size: 16px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.4;
  }

  h5 {
    font-size: 13px;
    margin: 0;
    line-height: 1.4;
  }

  h6 {
    font-size: 12px;
    margin: 0;
    line-height: 1.7;
  }

  ul {
    list-style-type: disc;
    margin-left: 2rem;
    /* margin-bottom: 1rem; */
  }

  ol {
    list-style-type: decimal;
    margin-left: 1rem;
    /* margin-bottom: 1rem; */
  }

  li {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b {
    font-family: "AB Chanel", sans-serif;
    font-weight: 500 !important;
  }

  p,
  span,
  ul,
  li,
  ol,
  div {
    font-size: 14px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
