.engaged-purchaser ul {
  margin: 0;
  list-style: none;
  text-align: start;
  display: flex;
  flex-direction: column;
}

.engaged-purchaser ul li {
  display: flex;
  flex-direction: column;
}

.engaged-purchaser ul b {
  font-size: 14px !important;
  margin: 0.5rem 0rem;
}
