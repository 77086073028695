@font-face {
  font-family: "ABC Corporate Extra Light";
  src: url("../../assets/fonts/abc-corporate-extra-light.ttf")
    format("truetype");
  font-style: normal;
}
.authorise-form p,
.authorise-form span,
.authorise-form ul,
.authorise-form li,
.authorise-form ol,
.authorise-form div {
  font-size: 12px;
}

.authorise-form label {
  font-family: "ABC Corporate Extra Light", sans-serif;
  font-size: 12px;
}

.authorise-form input,
.authorise-form select {
  font-family: "ABC Corporate Extra Light", sans-serif;
  min-height: 24px !important;
  max-height: 32px !important;
  font-size: 10px !important;
  padding: 0.5rem;
}

.terms-condition {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 9px !important;
  line-height: 1.6;
  /* padding: 60px; */
  padding: 8px;
}

/* ----------------------------------- *
*   Typography
* ----------------------------------- */
.terms-condition h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 700 !important;
  margin: 1rem 0rem;
}

.terms-condition h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: 700 !important;
  margin: 1rem 0rem;
}

.terms-condition h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  line-height: 1.6;
  font-weight: 700 !important;
  margin: 0.3rem 0rem;
}

.terms-condition h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 9px;
  line-height: 1.6;
  font-weight: 700 !important;
  margin: 0.2rem 0rem;
}

.terms-condition h5 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 8px;
  color: #666666;
}

.terms-condition p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 9px;
  margin-bottom: 0.5rem;
}

.terms-condition span {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 9px;
}

.terms-condition strong {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 9px;
}

.terms-condition ol {
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 0.5rem;
  position: relative;
  margin-top: 0.3rem;
  font-size: 9px;
}

.terms-condition td {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 9px;
}

.terms-condition tr {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 9px;
}

.terms-condition ol[type="a"] {
  list-style-type: lower-alpha;
}

.terms-condition ol[type="i"] {
  list-style-type: lower-roman; /* Ensure Roman numerals are used */
}

.terms-condition ol li {
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 0.3rem;
  font-size: 9px;
}

.terms > ol {
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 0rem;
  margin-left: 0rem;
  counter-reset: item;
  /* -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 20px; */
}

.terms > ol > li {
  font-family: Arial, Helvetica, sans-serif;
  /* color: #666666; */
  display: block;
}

.terms > ol > li:before {
  font-family: Arial, Helvetica, sans-serif;
  content: counters(item, ". ") ". ";
  counter-increment: item;
}

.terms > ol > li > ol {
  font-family: Arial, Helvetica, sans-serif;
  counter-reset: item;
  padding-left: 0.5rem;
  margin-left: 0rem;
}

.terms > ol > li > ol > li {
  font-family: Arial, Helvetica, sans-serif;
  display: block;
  padding-left: 0.5rem;
  margin-left: 0rem;
}

.terms > ol > li > ol > li:before {
  font-family: Arial, Helvetica, sans-serif;
  content: counters(item, ". ") ". ";
  counter-increment: item;
}

.terms-condition small {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 8px;
  color: #9f9f9f;
  font-weight: normal;
}
