/* .rbc-row-content {
  min-height: 200px !important;
} */

.rbc-month-row {
  overflow: visible !important;
}

.rbc-timeslot-group {
  min-height: 200px !important;
}

/* General calendar styles */
.rbc-event {
  max-width: 250px;
  /* background-color: black !important; */
  color: white !important;
  border-radius: 0px;
  padding: 4px;
  font-size: 14px !important;
}

.rbc-overlay {
  max-height: 400px;
  overflow-y: auto;
  min-width: 320px !important;
  /* top: 100px !important; */
}

.rbc-event-content {
  font-size: 11px !important;
}

.rbc-toolbar-label {
  padding: 0.5rem !important;
}

/* Responsive for mobile */
@media (max-width: 600px) {
  .rbc-event {
    font-size: 10px !important;
    padding: 2px;
  }

  .rbc-day-bg {
    font-size: 10px;
  }

  .event-modal {
    font-size: 14px;
  }
  .rbc-event-content {
    font-size: 10px !important;
  }
  .rbc-overlay {
    max-height: 400px;
    overflow-y: auto;
    left: 16px !important;
  }
}

/* Calendar navigation styles */
.calendar-navigation {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.icon-button {
  background: none;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

/* Event modal styles */
.event-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.event-details {
  font-size: 16px;
}

.event-modal::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

/* calendar */

.react-calendar {
  width: auto !important;
  max-width: 400px !important;
}

.react-calendar__tile--active {
  background-color: black !important;
  color: white !important;
  /* border-radius: 30px; */
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__tile--now {
  background: #ffff;
}

.react-calendar__tile--now:hover {
  background: #dfdede !important; /* Keep it white on hover */
}

.react-calendar__tile--now:focus {
  background: black !important; /* Keep it white on hover */
}

.react-calendar_month-view_days {
  justify-content: space-between;
  padding: 0.2rem;
}

.react-calendar_month-viewdays .react-calendar_tile {
  display: inline-flex !important;
  flex: 0 0 12.8% !important;
  width: 40px; /* Adjust width */
  height: 40px; /* Adjust height */
  border-radius: 50%; /* Circular shape */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5; /* Optional: light background for tiles */
  margin-bottom: 1rem !important;
}

.booking-form label {
  font-family: "ABC Corporate Extra Light", sans-serif;
  font-size: 12px;
}

.booking-form input,
.booking-form select {
  font-family: "ABC Corporate Extra Light", sans-serif;
  font-size: 12px !important;
}
.react-calendar {
  border: 1px solid #e5e5e5 !important;
}

.eventDisplay {
  font-size: 10px !important;
  line-height: 1.5 !important;
}
