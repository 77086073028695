/* .rbc-row-content {
  min-height: 200px !important;
} */

.rbc-month-row {
  overflow: visible !important;
}

/* General calendar styles */
.rbc-event {
  background-color: black;
  color: white !important;
  border-radius: 0px;
  padding: 4px;
  font-size: 14px !important;
}

.rbc-overlay {
  max-height: 400px;
  overflow-y: auto;
  min-width: 320px !important;
  /* top: 100px !important; */
}

.rbc-event-content {
  font-size: 11px !important;
}

.rbc-toolbar-label {
  padding: 0.5rem !important;
}

/* Responsive for mobile */
@media (max-width: 600px) {
  .rbc-event {
    font-size: 10px !important;
    padding: 2px;
  }

  .rbc-day-bg {
    font-size: 10px;
  }

  .event-modal {
    font-size: 14px;
  }
  .rbc-event-content {
    font-size: 10px !important;
  }
  .rbc-overlay {
    max-height: 400px;
    overflow-y: auto;
    left: 16px !important;
  }
}

/* Calendar navigation styles */
.calendar-navigation {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.icon-button {
  background: none;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

/* Event modal styles */
.event-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.event-details {
  font-size: 16px;
}

.event-modal::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1011;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  margin: 1rem;
}

.rbc-row-content {
  min-height: 250px !important;
}

.rbc-month-row {
  overflow: visible !important;
}

.rbc-event-content {
  /* white-space: normal !important; */
  white-space: pre-line !important; /* Allows new lines in event titles */
}
